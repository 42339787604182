<template>
  <CardAnimation>
    <div class="card-staff">
      <div class="staff" @click="redirectToHonorDetail(staff.id)">
        <img :src="staff.image" class="image-staff" alt="image-staff"/>
      </div>
      <div class="card-staff__content">
        <div style="height: 100%" @click="redirectToHonorDetail(staff.id)">
          <h3 class="title line-clamp-1">{{ staff?.name }}</h3>
          <label class="description line-clamp-1">{{ staff?.description }}</label>
          <label v-if="staff?.apartment" class="description line-clamp-1">{{ staff?.apartment }}</label>
          <p class="content line-clamp-3">
            {{ staff?.content }}
          </p>
        </div>
        <div class="action">
          <ActionComponent :post="staff" type-post="staff"/>
        </div>
      </div>
    </div>
  </CardAnimation>
</template>

<script>
import ActionComponent from "@/views/components/honors/ActionComponent.vue";
import CardAnimation from "@/views/components/honors/CardAnimation.vue";

export default {
  components: {CardAnimation, ActionComponent},
  props: {
    staff: {
      type: Object,
      required: true,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {}
    }
  },
  methods: {
    redirectToHonorDetail(idStaff) {
      this.$router.push({
        name: 'honor-detail',
        params: {id: idStaff},
        query: {type: 'staff'}
      })
    }
  }
}
</script>

<style lang="css" scoped>
  .card-staff {
    display: grid;
    align-items: center;
    grid-template-columns: 6fr 6fr;
    border-radius: 40px;
    background-image: linear-gradient(to right, #6050AF, #56479F, #473986);
    cursor: pointer;
  }
  .title {
    color: #AA8CFF;
    font-weight: bold;
    font-size: 30px;
  }
  .card-staff__content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 15px;
    height: 100%;
  }
  .description {
    font-size: 25px;
    color: white;
  }
  .content {
    font-size: 16px;
    color: white;
  }
  .staff {
    background-image: url("../../../assets/img/rounded-image.png");
    background-position: center center;
    background-size: 350px;
    position: relative;
    overflow: hidden;
    width: 300px;
    height: 300px;
  }
  .image-staff {
    position: absolute;
    border-radius: 100%;
    width: 205px;
    height: 205px;
    background-size: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
  .action {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 767px) {
    .card-staff {
      border-radius: 20px;
      box-shadow: 5px 25px 25px -25px rgb(0 0 0 / 0.25);
    }
    .staff {
      background-size: 170px;
      width: 140px;
      height: 140px;
    }
    .image-staff {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
    .title {
      font-size: 16px;
      cursor: pointer;
    }
    .description {
      font-size: 16px;
      cursor: pointer;
    }
    .content {
      display: none;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .card-staff {
      grid-template-columns: 1fr 1fr;
      align-items: center;
      justify-content: center;
    }
    .staff {
      width: 100%;
    }
    .action {
      margin-bottom: 20px;
    }
  }
</style>