import { render, staticRenderFns } from "./CardOKR.vue?vue&type=template&id=4990cbcc&scoped=true"
import script from "./CardOKR.vue?vue&type=script&lang=js"
export * from "./CardOKR.vue?vue&type=script&lang=js"
import style0 from "./CardOKR.vue?vue&type=style&index=0&id=4990cbcc&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4990cbcc",
  null
  
)

export default component.exports