<template>
  <div class="layout" style="margin-top: 50px">
    <div
         v-for="(item, index) in listHonors"
         :key="`${item.name + index + item.title}`"
    >
      <CardOKRItem :okr-item="item"/>
    </div>
  </div>
</template>

<script>

import CardOKRItem from "@/views/components/honors/CardOKRItem.vue";
import {HonorModel} from "@/views/pages/honors/Model";

export default {
  components: {CardOKRItem},
  data(){
    return {
      listHonors: []
    }
  },
  props: {
    listApartment: {
      type: Array,
      required: true,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    typeCard: {
      type: String,
      default: 'normal'
    }
  },
  mounted() {
    this.convertToModel();
  },
  methods: {
    convertToModel() {
      if (this.typeCard === "carousel") {
        this.listHonors = this.listApartment
      } else {
        this.listHonors = this.listApartment.map(item => {
          return new HonorModel(
              item?.id,
              item?.category_name,
              item?.newsletter_desc,
              item?.newsletter_like,
              item?.newsletter_share,
              item?.content,
              item?.attach_files[0],
              item?.department,
              item?.user_like?.like === 1 ? 1 : 0,
              item?.user_like?.share > 0
          )
        })
      }
    }
  }
}
</script>

<style lang="css" scoped>
  .layout {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
  }
  @media only screen and (max-width: 1280px) {
    .layout {
      grid-template-columns: 1fr 1fr;
      gap: 15px;
    }
  }
@media only screen and (min-width: 1025px) {
  .card-staff {
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
  }
}
</style>