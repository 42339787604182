<template>
  <div style="display: flex; gap: 12px">
    <button class="btn-custom" @click="updateLike(post)">
      <i style="color: red;" v-bind:class="post?.liked === 1 ? 'fas fa-heart' : 'fal fa-heart'"></i>
      <span style="color: #E20717">({{ post?.heart }})</span>
    </button>
    <button class="btn-custom" ref="fbShareButton" @click="handleShare(post)" data-layout="button_count"
            style="color: #503694">
      <i v-bind:class="post?.shared ? 'fas fa-share' : 'fal fa-share'"></i>
      <span>({{ post?.countShare }})</span>
    </button>
  </div>
</template>

<script>
import { UPDATE_ACTION_REACT } from "@/core/services/store/service.module";

export default {
  props: {
    post: {
      type: Object,
      required: true,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },
    typePost: {
      type: String,
      default: "",
      required: true,
    }
  },
  head() {
    return {
      title: this.post.name,
      meta: [
        { hid: 'description', name: 'description', content: this.post.description },
        { property: 'og:title', content: this.post.name },
        { property: 'og:description', content: this.post.description },
        { property: 'og:image', content: this.post.image },
      ]
    };
  },
  data() {
    return {
      urlToShare: window.location.href
    };
  },
  mounted() {
  },
  methods: {
    updateLike(postItem) {
      this.handleReact(postItem, true).then(res => {
        if (postItem?.liked === 1) {
          // eslint-disable-next-line vue/no-mutating-props
          this.post.heart--;
          // eslint-disable-next-line vue/no-mutating-props
          this.post.liked = 0;
        } else {
          // eslint-disable-next-line vue/no-mutating-props
          this.post.heart++;
          // eslint-disable-next-line vue/no-mutating-props
          this.post.liked = 1;
        }
      }).catch((response) => {
        console.log(response.error)
      });
    },
    updateShare(postItem) {
      return this.handleReact(postItem, false);
    },
    handleReact(postItem, isLike) {
     return this.$store.dispatch(UPDATE_ACTION_REACT, { id: postItem.id, is_like: isLike })
    },
    handleShare(postItem) {
      let props = this.$router.resolve({
        name: 'honor-detail',
        params: { id: postItem.id},
        query: {type: this.typePost}
      });
      this.shareOnFacebook(props.href).then(() => {
        this.updateShare(postItem).then((res) => {
          // eslint-disable-next-line vue/no-mutating-props
          this.post.countShare ++;
          // eslint-disable-next-line vue/no-mutating-props
          this.post.shared = true;
        });
      }).catch((error) => {

      })
    },
    shareOnFacebook(url) {
      let url_share = url ?? window.location.href
      return new Promise((resolve, reject) => {
        const data = {
          method: 'share',
          href: `${process.env.VUE_APP_BASE_URL}${url_share}`,
        };
        FB.ui(data, function (response) {
          try {
            if (response === null || response === undefined) {
              reject(new Error('Share canceled'));
              return;
            }

            if (typeof(response)=== typeof ([])) {
              resolve(response);
              return;
            }
          }catch (e) {
           return reject(new Error('Share failed'));
          }
        });
      });
    }
  }
}
</script>

<style lang="css" scoped>
.btn-custom {
  display: flex;
  align-items: center;
  gap: 5px;
  border: none;
  background: white;
  border-radius: 9999px;
  padding: 5px 12px;
}

@media only screen and (max-width: 767px) {
  .btn-custom {
    padding: 2px 6px;
    gap: 2px;
  }
}
</style>